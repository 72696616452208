import React from 'react';
import BaseMetric from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseMetric';

class PipelinesSummary extends BaseMetric {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            date: null,
            data: {
                successful: 0,
                failed: 0,
                upcoming: 0,
            },
        }
    }

    getStatus() {
        if (this.state.isLoading) {
          return "loading";
        }
        if (this.state.data.failed == 0 && this.state.data.missing == 0) {
          return "pass"
        } else {
          return "fail"
        }
    }

    getTitle() {
        return "Summary";
    }

    getApiPath() {
        return "status/pipelines/summary";
    }

    renderIndivMetric(metric, title, required) {
        // if not required, will not be rendered if 0
        if (!required) {
            if (this.state.data[metric] == 0) {
                return;
            }
        }
        return (
            <div className="level-item has-text-centered">
                <div>
                <p className="heading">{title}</p>
                <p className="title">{this.state.data[metric]}</p>
                </div>
            </div>
        )
    }

    renderMetric() {
        return (
            <div className="level">
                { this.renderIndivMetric("successful", "Successful", true) }
                { this.renderIndivMetric("failed", "Failed", true) }
                { this.renderIndivMetric("missing", "Missing", false)}
            </div>
        )
    }
}


export default PipelinesSummary
