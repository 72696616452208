import React, { Component } from 'react';
import { Link } from "@reach/router"

export default class PageErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({ hasError: true });
      // You can also log the error to an error reporting service
      console.log(error, info);
    }

    clearErrorUI() {
        this.setState({hasError: false})
    }

    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            <div className="home-main content" style={{padding: '1em'}}>
                <h1>Something went wrong.</h1>
                <p>
                    Unable to render the page. Please contact the administrator.
                </p>
                <Link to="/" onClick={this.clearErrorUI}>Back to Home</Link>
            </div>
        );
      }
      return this.props.children;
    }
}
