import React from 'react';
import BaseMetric from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseMetric';
import { Link } from 'gatsby';


class PipelineOverview extends BaseMetric {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            date: null,
            data: {
                errors: [],
                summary: {
                    failed: 0,
                    successful: 0,
                    upcoming: 0,
                }
            },
            page: 0,
        }

        this.logPerPage = 3;
    }

    getStatus() {
        if (this.state.isLoading) {
          return "loading";
        }
        if (this.state.data.summary.failed == 0 && this.state.data.summary.missing == 0) {
          return "pass"
        } else {
          return "fail"
        }
    }

    getTitle() {
        if (this.props.title) {
          return (
            <Link to="/status/pipelines"> {this.props.title} </Link>
          )
        } else {
          return "Error Reporting";
        }
    }

    getApiPath() {
        return "status/pipelines/overview";
    }

    getItemKey(error_message) {
        return `${error_message.pipeline}-${error_message.message}`;
    }


    renderLogs(visibleLogs) {
        if (visibleLogs.length == 0) {
            return (
                <div>
                    <p className="header">No errors.</p>
                </div>
            )
        } else {
            return (
                <div>
                    <ul>
                        {
                            visibleLogs.map(
                                (e) => (<li key={this.getItemKey(e)}><p>
                                    <a href={e.executionLink}><strong>{e.pipeline}</strong> </a>
                                    - {e.message.slice(0, 100)}
                                    { e.message.length > 100 ? '...' : ''}
                                </p></li>)
                            )
                        }
                    </ul>
                </div>
            )
        }
    }

    renderPagination(validLogs) {
        if (validLogs.length <= this.logPerPage ) {
            return (<div></div>)
        }
        let maxPage = Math.ceil(validLogs.length / this.logPerPage);

        return (
            <div style={{marginTop: '12px'}}>
                <nav className="pagination is-small is-centered">
                    <a
                        className="pagination-previous"
                        onClick={() => {
                            if (this.state.page > 0) {
                                this.setState({page: this.state.page - 1})}
                            }
                        }
                    >Previous</a>

                    <a
                        className="pagination-next"
                        onClick={() => {
                            if (this.state.page < maxPage - 1) {
                                this.setState({page: this.state.page + 1})}
                            }
                        }
                    >Next</a>
                    <ul className="pagination-list">
                        {
                            [...Array(maxPage).keys()].map(
                                (k) => (
                                    <li className={
                                            (k == this.state.page) ?
                                            "pagination-link is-current"
                                            : "pagination-link"
                                        }
                                            onClick={() => this.setState({page: (k)})}
                                        >{(k + 1).toString()}
                                    </li>
                                )
                            )
                        }
                    </ul>
                </nav>
            </div>
        )
    }

    renderIndivMetric(metric, title, required) {
        if (!required) {
            if (this.state.data.summary[metric] == 0) {
                return;
            }
        }
        return (
            <div className="level-item has-text-centered">
                <div>
                <p className="heading">{title}</p>
                <p className="title">{this.state.data.summary[metric]}</p>
                </div>
            </div>
        )
    }

    renderSummary() {
        return (
            <div className="level">
                { this.renderIndivMetric("successful", "Successful", true) }
                { this.renderIndivMetric("failed", "Failed", true) }
                { this.renderIndivMetric("missing", "Missing", false)}
            </div>
        )
    }

    renderMetric() {
        let visibleLogs = this.state.data.errors.slice(
            (this.state.page) * this.logPerPage, (this.state.page + 1) * this.logPerPage
        )
        return (
            <div className="tiles">
                <p className="heading">Summary</p>
                { this.renderSummary() }
                <div>
                    <p className="heading">Errors</p>
                    { this.renderLogs(visibleLogs) }
                    { this.renderPagination(this.state.data.errors) }
                </div>
            </div>
        )
    }
}


export default PipelineOverview