import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { pipelineJson } from '@tra-sg/gatsby-theme-c360-portal/src/data/config';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


export default class UserTableSchema extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      data: null
    };
  }


  fetchData() {
    const { table_id, zone_id, dataset_id, group_id } = this.props;
    let callApiUrl = `dataset/table/get_data_exploration_json?dataset=${dataset_id}&table=${table_id}&zone=${zone_id}&groups=${group_id}`

    this.setState({ isLoading: true });

    callApi(
      callApiUrl,
      (result) => {
        let loaded_result = result.data;

        if (loaded_result == null) throw Error('Invalid Table Schema received.');
        this.setState({
          isLoading: false,
          error: null,
          data: loaded_result
        });
      },
      (error) => this.setState({ error, isLoading: false })
    )
  }


  componentDidMount() {
    this.fetchData();
  }


  componentDidUpdate(prevProps) {
    if (this.props.dataset_id !== prevProps.dataset_id || this.props.zone_id !== prevProps.zone_id || this.props.table_id !== prevProps.table_id) {
      this.fetchData();
    }
  }


  render() {
    const { error, isLoading, data } = this.state;

    if (error) {
      return (
        <div>
          Table Schema is not available at the moment. Please comeback later.
        </div>
      );
    }

    if (isLoading) {
      return (
        <div>
          <div className="iframe-holder" />
        </div>
      );
    }

    let schemaDiv = []
    let schemaHeader = []
    let schemaNull = []

    if (data) {
      if (data.variables) {
        let cols = Object.keys(data.variables)
        cols.map(key => {
          schemaHeader.push(
            <p className="has-text-weight-light is-size-7" key={key}>{key}</p>
          )
          schemaDiv.push(
            <p className="has-text-weight-light is-size-7" key={data["variables"][key]["type"]}>{data["variables"][key]["type"]}</p>
          )
          schemaNull.push(
            <p className="has-text-weight-light is-size-7" key={data["variables"][key]["nullable"]}>{data["variables"][key]["type"]}</p>
          )
        })
      }
    }

    return (
      <div className="columns half-vh is-mobile">
        <div className="column">
          <h6>Column</h6>
            {schemaHeader}
        </div>
        <div className="column">
          <h6>Datatype</h6>
            {schemaDiv}
        </div>
        <div className="column">
          <h6>Nullable</h6>
          {schemaNull}
        </div>
      </div>
    );
  }
}
