/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import React from 'react';

// Handling SignIn Failure should be registered before Auth actually processes the
// response (due to redirects). Here, we handle it even before Auth is configured.
// See: https://github.com/aws-amplify/amplify-js/issues/4319#issuecomment-674024970

const isBrowser = typeof window !== 'undefined';

Hub.listen('auth', ({ payload: { event, data } }) => {
  switch (event) {
    case 'signIn_failure':
      if (isBrowser) {
        localStorage.removeItem('login-provider');
        localStorage.setItem('signinFailureError', data);
      }
      break;
    default:
      break;
  }
});

Auth.configure({
  region: 'ap-southeast-1',
  userPoolId: process.env.GATSBY_USER_POOL_ID,
  userPoolWebClientId: process.env.GATSBY_USER_POOL_WEB_CLIENT_ID,
  mandatorySignIn: true,
  oauth: {
    domain: process.env.GATSBY_OAUTH_DOMAIN,
    redirectSignIn: process.env.GATSBY_OAUTH_REDIRECT_SIGNIN,
    redirectSignOut: process.env.GATSBY_OAUTH_REDIRECT_SIGNOUT,
    responseType: 'code',
  },
});

const onPreRouteUpdate = (state, page, pages) => {
  // signal to various components listening to 'auth' changes
  // that they should re-evaluate log-in status
  Hub.dispatch('auth', { event: 'onRouteUpdate', data: { state, page, pages } });
};

export const wrapPageElement = ({ element }) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      {element}
    </QueryClientProvider>
  );
};

export default { onPreRouteUpdate, wrapPageElement };
