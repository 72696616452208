import React from 'react';
import BaseMetric from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseMetric';
import PipelineCard from '@tra-sg/gatsby-theme-c360-portal/src/components/StatusPipelines/PipelineCard'

class PipelineHistory extends BaseMetric {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: null,
      date: null,
      data: [],
      page: 0,
    }

    this.cardPerPage = 9;
  }

  getStatus() {
    if (this.state.isLoading) {
      return "loading";
    }

    let pipelines_latest_status = this.state.data.map(
      (pipeline) => {
          if (pipeline.history.length == 0) {
              return {
                status: "missing",
              }
          }

          return pipeline.history[pipeline.history.length-1]
      }
    );

    var status = "pass";
    pipelines_latest_status.forEach((pipeline) => {
      if (pipeline.status != "Successful") {
        // anything that is not "Successful" returns a "fail"
        status = "fail";
      }
    })

    return status;
  }

  getTitle() {
    return "Pipeline History"
  }

  getApiPath() {
    return "status/pipelines/pipeline_history";
  }

  getItemKey(pipeline) {
    return pipeline.pipelineName;
  }

  renderPagination(validLogs) {
    if (validLogs.length <= this.cardPerPage) {
      return (<div></div>)
    }
    let maxPage = Math.ceil(validLogs.length / this.cardPerPage);

    return (
      <div style={{ marginTop: '12px' }}>
        <nav className="pagination is-small is-centered">
          <a
            className="pagination-previous"
            onClick={() => {
              if (this.state.page > 0) {
                this.setState({ page: this.state.page - 1 })
              }
            }
            }
          >Previous</a>
          <a
            className="pagination-next"
            onClick={() => {
              if (this.state.page < maxPage -1) {
                this.setState({ page: this.state.page + 1 })
              }
            }
            }
          >Next</a>
          <ul className="pagination-list">
            {
              [...Array(maxPage).keys()].map(
                (k) => (
                  <li className={
                    (k == this.state.page) ?
                      "pagination-link is-current"
                      : "pagination-link"
                  }
                    onClick={() => this.setState({ page: (k) })}
                  >{(k + 1).toString()}
                  </li>
                )
              )
            }
          </ul>
        </nav>
      </div>
    )
  }

  renderCards(visiblePipeline) {
    if (visiblePipeline.length == 0) {
      return (
        <div>
          <p className="header">No item.</p>
        </div>
      )
    } else {
      return (
        <div className="columns is-multiline">
          {
            visiblePipeline.map((v) => {
              return (
                <div className="column is-one-third">
                  <PipelineCard
                    key={this.getItemKey(v)}
                    pipelineName={v.pipelineName}
                    expectedSchedule={v.expectedSchedule}
                    pipelineStatus={v.status}
                    nextRuntime={v.nextRuntime}
                    history={v.history}
                  />
                </div>
              )
            })
          }
        </div>
      )
    }
  }

  comparePipeline(a, b) {
        // A comparison function for sorting purposes

        // compare if either pipeline has no history
        if (a.history.length == 0 && b.history.length == 0) return 0;
        if (a.history.length == 0) return 1;
        if (b.history.length == 0) return -1;

        if (a.history[a.history.length - 1].executionTime < b.history[b.history.length - 1].executionTime) {
          return 1
        }
        if (a.history[a.history.length - 1].executionTime > b.history[b.history.length - 1].executionTime) {
          return -1
        }
        if (a.history[a.history.length - 1].executionTime = b.history[b.history.length - 1].executionTime) {
          if (a.pipelineName > b.pipelineName) {
            return 1
          }
          if (a.pipelineName < b.pipelineName) {
            return -1
          }
          if (a.pipelineName == b.pipelineName) {
            return 0
          }
        }
    }

  renderMetric() {
    let failedPipeline = this.state.data.filter((v) => v.status != "Successful");

    failedPipeline = failedPipeline.sort((a, b) => this.comparePipeline(a, b));

    let successPipeline = this.state.data.filter((v) => v.status == "Successful");

    successPipeline = successPipeline.sort((a, b) => this.comparePipeline(a, b));
    // show non-successful first
    var validPipeline = failedPipeline.concat(successPipeline);

    let visiblePipeline = validPipeline.slice(
      (this.state.page) * this.cardPerPage, (this.state.page + 1) * this.cardPerPage
    )

    return (
      <div className="tiles">
        {/* <p className="heading">Summary</p> */}
        <div>
          { this.renderCards(visiblePipeline) }
          {this.renderPagination(validPipeline)}
        </div>
      </div>
    )
  }
}


export default PipelineHistory
