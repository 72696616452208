import React, { Component } from 'react';
import MailchimpPlugin from '@tra-sg/gatsby-theme-c360-portal/src/components/MailchimpPlugin';
import { italic } from 'ansi-colors';


class IframeComponent extends Component {
  /*
  Props:
    tenant: tenant name e.g "acme"
    stage: "prod" or "staging"
    dashboard_slug: for superset dashboard, either the slug e.g. "clv_overview" or dashboard_id e.g. "122"
    mailchimp: null or 'enabled'; indicate whether this dashboard has mailchimp support
    manual_height: boolean to indicate whether the height is set manually by style; defaults to auto-resize
    comparison: boolean to indicate side-by-side comparison of the same dashboard
  */
  constructor(props) {
    super(props);
    this.state = {
        loading: true,
        style: {
          height: 0,  // default height before adjustment
        },
    };
  }

  componentDidMount() {
    if (!this.props.manual_height) {
      window.addEventListener('message', this.handleDashboardSize.bind(this));

      // if after 2.5 seconds no dashboard height is reported,
      // default to given style height (or 1000 if height not given)

      var fallback_height = 1000;
      if ( this.props.style ) {
        fallback_height = this.props.style.height || fallback_height;
      }

      let snap_to_fallback_height = () => {
        if ( this.state.style.height <= 0 ) {
          this.setState({
            loading: false,
            style: {
              height: fallback_height,
            }
          })
        }
      }

      setTimeout(snap_to_fallback_height, 2500);
    }
  }

  hideSpinner = () => {
    if (this.props.manual_height) {
      this.setState({
        loading: false,
      });
    }
  }

  handleDashboardSize = (event) => {
    // handle height from datastudio
    if (event.origin == "https://datastudio.google.com") {
      if (event.currentTarget.innerHeight > 0) {
        let heightVw = Math.ceil(
          (event.currentTarget.innerHeight / event.currentTarget.innerWidth) * 85
        ) + 1;
        // 85vw is the set width for iframe, and +1 because from experiments its
        // found to have just a little bit of scroll.
        // This still doesn't work well if you resize the web browser, but on normal
        // view it should work as expected.
        console.log("setting height to ", heightVw)
        this.setState({
          style: {
            height: `${heightVw}vw`,
          }
        })
      }
    }

    if (event.origin !== 'https://' + this.getDashboardDomain() ) {
      console.log("Received events from iframe with unknown origin: " + event.origin, event);
      return;
    }

    if (event.data.event_id !== 'superset_dashboard_size') return;

    if (event.data.size.height > 0) {
      this.setState({
        loading: false,
        style: {
          height: event.data.size.height,
        }
      });
    }
  }


  renderIntegration() {
    if (!this.state.loading) {
      return(
        <div className="section columns">
          {this.props.mailchimp == "enabled" ? this.renderMailchimp() : null}
        </div>
      )
    }
  }

  renderMailchimp() {
    return(
      <MailchimpPlugin origin={'https://' + this.getDashboardDomain()} />
    )
  }

  renderLoadCircle() {
      return (
        <div className="columns is-centered">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      )
  }

  renderIframe(src, style) {
    const { comparison } = this.props;
    if (comparison) {
      return this.renderDoubleIframe(src, style);
    } else {
      return this.renderSingleIframe(src, style);
    }

  }

  renderSingleIframe(src, style)  {
    style={
      width: "85vw",
    }
    let combinedStyle = { ...style, ...this.state.style }
    return (
      <div className='columns is-tablet is-centered'>
        <iframe
          className="datastudio-iframe"
          id={"dashboard" + this.props.dashboard_slug}
          src={src}
          frameBorder="1px"
          onLoad={this.hideSpinner}
          style={combinedStyle}
          allowFullScreen
          title="iframe"
        />
      </div>
    );
  }

  renderDoubleIframe(src, style) {
    return (
      <div className='columns'>
        {this.renderSingleIframe(src, style) }
        {this.renderSingleIframe(src, style) }
      </div>
    )
  }

  getDashboardDomain() {
    var domain;
    var tenant = this.props.tenant;
    if ( this.props.stage == "prod") {
        domain = `superset.${tenant}.c360.ai`
    } else {
      domain = `superset-staging.${tenant}.c360.ai`
    }
    return domain;
  }

  getDashboardURL() {
    if ( this.props.dashboard_url ) { return this.props.dashboard_url; }
    var dashboard_url = `https://${this.getDashboardDomain()}/superset/dashboard/${this.props.dashboard_slug}/?standalone=true`
    return dashboard_url;
  }

  renderWarning() {
    const { stage } = this.props;
    if ( stage == "prod" ) {
      return;
    } else {
      return (
        <div className='section'>
          <article className='message is-warning'>
            <div className='message-body'>
                This dashboard is marked as <b><i>staging</i></b> and may change without notice.
            </div>
          </article>
        </div>
      )
    }
  }

  render() {
    var dashboard_url = this.getDashboardURL()
    const { loading } = this.state;
    return (
      <div>
        { this.renderWarning() }
        { this.renderIframe(dashboard_url, this.props.style) }
      </div>
    )
  }
}

export default IframeComponent;
