import fetch from 'node-fetch';
import { apiUrl, requiresLogin } from '@tra-sg/gatsby-theme-c360-portal/src/data/config';
import Auth from '@aws-amplify/auth'

import Cookies from 'universal-cookie';
const cookies = new Cookies();


// Auth Functions are wrapped within the functions below so that
// multiple components can access them.

export async function getAppUser() {
  // simple wrapper to Amplify Auth getCurrentUser
  return await Auth.currentAuthenticatedUser()
}

export async function loginToApp(options) {
  // simple wrapper to Amplify Auth FederatedSignIn
  // options - any options for Amplify FederatedSignIn e.g. { provider: 'Google' }
  Auth.federatedSignIn(options)
}

export async function logoutOfApp() {
  // simple wrapper to Amplify Auth signOut
  await Auth.signOut();
}


// The following are API-related functions


function getIdTokenFromSession(session) {
    let idToken = session.getIdToken()
    let jwt = idToken.getJwtToken()
    cookies.set("temp_cognito_token", jwt, {domain: '.acme.c360.ai'});
    return jwt;
}

async function getIdToken() {
    if ( !requiresLogin ) return '';
    try {
        let session = await Auth.currentSession();
        return getIdTokenFromSession(session);
    } catch (e) {
        console.log("getting session failed", e)
        await logoutOfApp()
        return ''
    }
}

export async function callApi(path, result_callback, error_callback, options={}) {
    /*
        options - bag of options for node-fetch.
    */
    let idToken = await getIdToken();
    if (!options.headers) options['headers'] = {};
    options.headers['Authorization'] = `Bearer ${idToken}`

    try {
        let response = await fetch(encodeURI(`${apiUrl}/${path}`), options);
        if ( !response.ok ) throw Error(response.status);
        let response_json = await response.json();
        result_callback(response_json);
    } catch (error) {
        error_callback(error);
    }
};

export async function callApiWithResult(path, options={}) {
    /*
        A flavor of callApi that is more suited for async/await syntatic sugar,
        that returns the fetch result instead of accepting callbacks.

        options - bag of options for node-fetch.
    */
    let idToken = await getIdToken();
    if (!options.headers) options['headers'] = {};
    options.headers['Authorization'] = `Bearer ${idToken}`

    let response = await fetch(encodeURI(`${apiUrl}/${path}`), options);
    if ( !response.ok ) throw Error(response.status);
    let response_json = await response.json();
    return response_json;
}
