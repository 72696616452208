import React from 'react';
import moment from 'moment';
import cronstrue from 'cronstrue';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStream, faSquare } from '@fortawesome/free-solid-svg-icons';
import { Link } from "@reach/router";


class PipelineCard extends React.Component {
    constructor(props) {
        super(props);
        this.colorMapping = {
            "Successful": "has-text-success",
            "Failed": "has-text-danger",
            "Timeout": "has-text-danger",
            "Upcoming": "has-text-info",
            "Late": "has-text-warning",
            "Status Unknown": "has-text-dark",
            "Aborted": "has-text-dark",
        }
    }

    getSchedule() {
        if (this.props.expectedSchedule) {
            return `Expected schedule: ${cronstrue.toString(this.props.expectedSchedule)} (UTC)`;
            // The library `cronstrue` currently do not support timezones, but
            // it seems to be coming sometimes in the future
            // https://github.com/bradymholt/cRonstrue/issues/94
        } else {
            return null;
        }
    }

    renderNextRuntime() {
        if (this.props.nextRuntime == null) return (
            <span
                className="has-text-weight-light is-size-7 has-text-grey"
            >
                Not a scheduled pipeline.
            </span>
        );

        if (this.getPipelineStatus() == "Failed") {
            let textColor = this.colorMapping[this.getPipelineStatus()]
            return (
                <span className="has-text-weight-light is-size-7 has-text-grey">
                    <span className={textColor}>[{this.getPipelineStatus()}] </span>
                </span>
            )
        } else if (["Late", "Timeout"].includes(this.getPipelineStatus())) {
            let textColor = this.colorMapping[this.getPipelineStatus()]
            return (
                <span
                    data-tooltip={this.getSchedule()}
                    className="has-text-weight-light is-size-7 has-text-grey"
                >
                    <span className={textColor}>[{this.getPipelineStatus()}] </span>
                    Next: {moment.unix(this.props.nextRuntime / 1000).format("MMM D[,] YYYY hh[:]mma [(UTC]Z[)]")}
                </span>
            )
        } else {
            return (
                <span
                    data-tooltip={this.getSchedule()}
                    className="has-text-weight-light is-size-7 has-text-grey"
                >
                    Next: {moment.unix(this.props.nextRuntime / 1000).format("MMM D[,] YYYY hh[:]mma [(UTC]Z[)]")}
                </span>
            )
        }
    }

    getPipelineStatus() {
        // return this.props.status || "Status Unknown";
        if (this.props.history.length == 0) {
            return "Status Unknown"
        } else {
            // return this.props.history[this.props.history.length-1]["status"]
            return this.props.pipelineStatus
        }
    }

    getHistoryBlockStatus(execution) {
        return `Executed at ${moment.unix(execution.executionTime / 1000).format("MMM D[,] YYYY hh[:]mma [(UTC]Z[)]")}`
    }

    renderHistoryBlocks() {
        if (this.props.history.length == 0) {
            return (
                <div>
                    <span
                        className="has-text-weight-light is-size-7 has-text-grey"
                    >
                        No executions in history.
                    </span>
                </div>
            )
        }
        return (
            <div style={{marginLeft: "2.25em"}}>
                {
                    this.props.history.map(
                        (execution) => (
                            <a
                                className={this.colorMapping[execution.status]}
                                data-tooltip={this.getHistoryBlockStatus(execution)}
                                href={execution.executionLink}
                            >
                                <FontAwesomeIcon icon={faSquare}/>{" "}
                            </a>
                        )
                    )
                }
            </div>
        );
    }

    renderTitle(status) {
        let icon = this.props.icon || faStream;
        let color_css = this.props.color_css || this.colorMapping[status];
        return (
            <Link to={`/status/pipeline/${this.props.pipelineName.toLowerCase()}`}>
              <div className="level" style={{marginBottom: "5px"}}>
                <div className="level-left">
                    <div className="level-item">
                        <span className={color_css} style={{fontSize: "1.5em"}}>
                            <FontAwesomeIcon icon={icon}/>
                        </span>
                    </div>
                    <div className="level-item">
                        <div>
                            <span className="subtitle">
                                {this.props.pipelineName}
                            </span>
                            <p>{this.renderNextRuntime()}</p>
                        </div>
                    </div>
                </div>
              </div>
            </Link>
        )
    }

    render() {
        let status = this.getPipelineStatus();
        return (
            <div className="card">
                <div className="card-content" style={{padding: '15px'}}>
                    {this.renderTitle(status)}
                    {this.renderHistoryBlocks()}
                </div>
            </div>
        )
    }
}


export default PipelineCard
