import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons';


function FeedItem({ item, defaultIcon }) {

    return (
        <div className='panel-block'>
            <span className="panel-icon is-size-4">
                <FontAwesomeIcon icon={item.icon || defaultIcon} />
            </span>
            <div>
                <p className="is-size-6">{item.title}</p>
                <p className="is-size-6">{item.description}</p>
                <p className="is-size-6">{item.content}</p>
                <p className="is-size-7">{item.datetime}</p>
            </div>
        </div>
    )
}


export default function Feed({ heading, items, defaultIcon }) {
    /*
        `items` - a list of objects with the following
            title:
            description:
            datetime:
            content:
            icon:   // fa-icon

    */

    let theDefaultIcon = defaultIcon || faTabletAlt;

    return (
        <div className='tile is-child panel'>
            <div className='panel-heading'>
                {heading}
            </div>
            {
                items.map(i => (<FeedItem item={i} defaultIcon={theDefaultIcon}/>))
            }
        </div>
    )
}
