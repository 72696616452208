import ModelBrowser from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser';
import KPIForecastDemo from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser/KPIForecastDemo';
import KPIForecastResult from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser/KPIForecastResult';
import QuickLook from '@tra-sg/gatsby-theme-c360-portal/src/components/QuickLook';
import ModelMetrics from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser/ModelMetrics';
import * as React from 'react';
export default {
  ModelBrowser,
  KPIForecastDemo,
  KPIForecastResult,
  QuickLook,
  ModelMetrics,
  React
};