import { faFlask, faChartLine, faSearchPlus, faShoppingCart, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QuickLook from '@tra-sg/gatsby-theme-c360-portal/src/components/QuickLook';
import React from 'react';
import { callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import { ReferenceLine, ReferenceArea } from 'recharts';


function sortByKey(array, key) {
  return array.sort((a, b) => {
    const x = a[key]; const y = b[key];
    if (x === y) {
      return 0;
    } if (x < y) {
      return -1;
    }
    return 1;
  });
}


function transformDataForPrediction(data, startDate, endDate, lookbackPeriod) {
  let dataSorted = sortByKey(data, 'date');

  var startDateIndex = null;
  var endDateIndex = null;
  dataSorted.forEach((e, i) => {
    if (e.date == startDate) {startDateIndex = i}
    if (e.date == endDate) {endDateIndex = i}
  })

  let minIndex = Math.max(0, startDateIndex - lookbackPeriod);
  let maxIndex = Math.min(data.length - 1, endDateIndex);
  let projectedPeriod = endDateIndex - startDateIndex;

  let lookbackData = []
  let fullData = []

  dataSorted.forEach((e, i) => {

    if (i >= minIndex && i <= startDateIndex) {
      lookbackData.push({
        ds: e.date,
        y: e.actual,
      })
    }

    if (i >= minIndex && i <= maxIndex) {
      fullData.push({
        ds: e.date,
        y: e.actual,
      })
    }
  })

  return { lookbackData, fullData, projectedPeriod };
}

function transformDataFromForecastResponse(prediction, fullData, date) {
  let actualDict = Object.assign({}, ...fullData.map((x) => ({[x.ds]: x.y})));

  let transformedData = prediction.map(
    (e) => {
      var item;

      if (e.ds < date) {
        item = {
          date: e.ds,
          // pred_band: [e.yhat_lower, e.yhat_upper],
          actual: actualDict[e.ds]
        }
      } else if (e.ds == date) {
        item = {
          date: e.ds,
          pred_band: [actualDict[e.ds],  actualDict[e.ds]],
          pred:  actualDict[e.ds],
          actual: actualDict[e.ds],
        }

      } else {
        item = {
          date: e.ds,
          pred: e.yhat,
          pred_band: [e.yhat_lower, e.yhat_upper],
          actual: actualDict[e.ds]
        }

      }

      return item;
    }
  )

  return transformedData;
}


class KPIForecastDemo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forecastStartDate: "2018-10-01",
      forecastEndDate: "2018-11-01",
      demoLookbackPeriod: 6,

      // salesRawLoading: true,
      salesRawLoading: false,
      salesRawData: null,
      salesRawDataError: null,

      forecastLoading: false,
      forecastingData: null,
      forecastingError: null,
    };
    this.onDemoDateChange = this.onDemoDateChange.bind(this);

    this.onForecastStartDateChange = this.onForecastStartDateChange.bind(this);
    this.onForecastEndDateChange = this.onForecastEndDateChange.bind(this);
    this.onDemoProjectedPeriodChange = this.onDemoProjectedPeriodChange.bind(this);
    this.onDemoLookbackPeriodChange = this.onDemoLookbackPeriodChange.bind(this);
    this.onClickGenerateForecast = this.onClickGenerateForecast.bind(this);
  }

  onDemoDateChange(selectedDate) {
    const ddate = [
      selectedDate.getFullYear(),
      (`0${selectedDate.getMonth() + 1}`).slice(-2),
      (`0${selectedDate.getDate()}`).slice(-2),
    ].join('');

    this.setState({
      demoDatePicker: selectedDate,
      demoDate: ddate,
    });
  }

  onForecastStartDateChange(event) {
    let { forecastEndDate, salesRawData } = this.state;
    let newStartDate = event.target.value;

    if (newStartDate >= forecastEndDate) {
      let dateList = salesRawData.map((e) => (e.date));
      let endDateIndex = dateList.indexOf(newStartDate) + 1;


      // reset forecastEndDate
      this.setState({
        forecastStartDate: newStartDate,
        forecastEndDate: dateList[endDateIndex],
      });
    } else {
      // only update forecastStartDate
      this.setState({forecastStartDate: newStartDate});
    }

  }

  onForecastEndDateChange(event) {
    this.setState({forecastEndDate: event.target.value});
  }

  onDemoProjectedPeriodChange(event) {
    this.setState({demoProjectedPeriod: event.target.value});
  }

  onDemoLookbackPeriodChange(event) {
    this.setState({demoLookbackPeriod: event.target.value});
  }

  async fetchSalesData() {
    this.setState({salesRawLoading: true});
    try {
      // let response_json = await callApiWithResult('lake/table/t360_kpi_performance?fields=date,actual');
      // response_json.data is in form of [{date: xxx, actual: xxx}]
      let response_json = [
        {date: '2021-05-01', actual: 123456},
        {date: '2021-06-01', actual: 123456},
        {date: '2021-07-01', actual: 123456},
        {date: '2021-08-01', actual: 123456},
        {date: '2021-09-01', actual: 123456},
        {date: '2021-10-01', actual: 123456},
        {date: '2021-11-01', actual: 123456},
        {date: '2021-12-01', actual: 123456},
        {date: '2022-01-01', actual: 123456},
        {date: '2022-02-01', actual: 123456},
        {date: '2022-03-01', actual: 123456},
        {date: '2022-04-01', actual: 123456},
        {date: '2022-05-01', actual: 123456},
      ]
      this.setState({
        salesRawLoading: false,
        salesRawData: sortByKey(response_json.data, 'date'),
      })
    } catch (error) {
      this.setState({
        salesRawLoading: false,
        salesRawData: null,
        salesRawDataError: error,
      })
    }
  }

  async onClickGenerateForecast() {
    this.setState({forecastLoading: true});

    let { salesRawData, forecastStartDate, forecastEndDate, demoLookbackPeriod } = this.state;

    let { lookbackData, fullData, projectedPeriod } = transformDataForPrediction(
      salesRawData, forecastStartDate, forecastEndDate, demoLookbackPeriod
    );

    let requestBody = {
      frequency: 'MS',
      projected_period: projectedPeriod,
      data: lookbackData,
    }

    try {
      let response_json = await callApiWithResult(
        'model/forecast/preview',
        {
          body: JSON.stringify(requestBody),
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (!response_json.predictions) {
        throw Error(response_json);
      }

      let transformedPrediction = transformDataFromForecastResponse(
        response_json.predictions, fullData, forecastStartDate
      )

      this.setState({ forecastingData: transformedPrediction, forecastLoading: false});
    } catch (error) {
      this.setState({
        forecastingError: error,
        forecastLoading: false,
      })
    }
  }

  componentDidMount() {
    this.fetchSalesData();
  }

  getStartDateOptionsFromSalesData() {
    let { salesRawData } = this.state;
    if (!salesRawData) return [];
    // start date cant be last date
    return salesRawData.map((e) => (e.date)).slice(0, salesRawData.length - 1);
  }

  getEndDateOptionsFromSalesData() {
    let { salesRawData, forecastStartDate } = this.state;
    if (!salesRawData) return [];
    let result = [];
    salesRawData.forEach(
      (e) => {
        if (e.date > forecastStartDate) {
          result.push(e.date);
        }
      }
    )
    return result;
  }

  renderSelectionReference() {
    let { forecastStartDate, forecastEndDate, forecastingData } = this.state;

    if (forecastingData) return null;

    if (!forecastEndDate) {
      return (
        <ReferenceLine x={forecastStartDate} label={{value: "Start Date", position: "insideTop"}} />
      )
    } else {
      return (
        <ReferenceArea x1={forecastStartDate} x2={forecastEndDate} label={{value: "Forecast Period", position: "insideTop"}} />
      )
    }
  }
  renderForecastControl() {
    let { forecastingData, forecastStartDate, forecastEndDate, demoLookbackPeriod } = this.state;

    if (forecastingData) {
      return (
        <div className="level">
          <div className="level-left">
            <div className="level-item has-text-centered">
              <div>
                <div className="heading">Forecast</div>
                <div className="select">
                  <select>
                    <option>Carbon</option>
                    <option disabled>Others...</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item has-text-centered">
              <div>
                <div className="heading">Action</div> {/*TODO: change to margintop*/}
                <button id="action-button" className="button" onClick={() => this.setState({forecastingData: null, forecastingError: null})}>Reset Forecast</button>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="level">
          <div className="level-left">
            <div className="level-item has-text-centered">
              <div>
                <div className="heading">Forecast</div>
                <div className="select">
                  <select>
                    <option>Carbon</option>
                    <option disabled>Others...</option>
                  </select>
                </div>
              </div>
            </div>
            <div id="forecast-demo-control-startdate" className="level-item has-text-centered">
              <div>
                <div className="heading">Forecast Start Date</div>
                <div className="select" onChange={this.onForecastStartDateChange}>
                  <select>
                    {
                      this.getStartDateOptionsFromSalesData().map(
                      (e) => (<option selected={e == forecastStartDate}>{e}</option>)
                      )
                    }
                  </select>
                </div>
              </div>
            </div>
            <div id="forecast-demo-control-enddate" className="level-item has-text-centered">
              <div>
                <div className="heading">Forecast End Date</div>
                <div className="select" onChange={this.onForecastEndDateChange}>
                  <select>
                    {
                      this.getEndDateOptionsFromSalesData().map(
                        (e) => (<option selected={e == forecastEndDate}>{e}</option>)
                      )
                    }
                  </select>
                </div>
              </div>
            </div>
            <div className="level-item has-text-centered">
              <div>
                <div className="heading">Lookback Period (months)</div>
                <div className="control">
                  <input className="input" type="text" placeholder="Lookback Period (months)" value={demoLookbackPeriod} onChange={this.onDemoLookbackPeriodChange}/>
                </div>
              </div>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item has-text-centered">
              <div>
                <div className="heading">Action</div> {/*TODO: change to margintop*/}
                <button id="action-button" className="button" onClick={this.onClickGenerateForecast}>Generate Forecast</button>
              </div>
            </div>
          </div>
        </div>
      )
    }

  }

  renderKPIDemoChart() {
    let { salesRawData, forecastingData } = this.state;
    var data;

    if (forecastingData) {
      data = forecastingData;
    } else if (salesRawData) {
      data = salesRawData;
    } else {
      data = this.props.data;
    }

    let { forecastLoading } = this.state;
      return (
          <div id="kpi-forecast-demo">
              <div>
                <p className="subtitle">Carbon Forecast Sandbox</p>
                <br/>
              </div>
              { this.renderForecastControl() }
              <div>
                { forecastLoading ? (<progress className="progress is-small is-primary"/>) : null }
              </div>
              <QuickLook
                subtitle="Carbon Forecast"
                chartType="line_w_band"
                data={data}
                X="date"
                Y={["pred_band", "actual", "pred"]}
                colorWheel={[
                  "#888888",
                  "#00b159",
                  "#f37735",
                ]}
                disableColorOffset
              >
                { this.renderSelectionReference() }
              </QuickLook>
          </div>
      )
  }

  renderLoading() {
    return (
      <div id="kpi-forecast-demo">
        <progress className="progress is-small is-primary"/>
      </div>
    )
  }

  render() {
    let { salesRawLoading } = this.state;
    return salesRawLoading ? this.renderLoading() : this.renderKPIDemoChart();
  }
}

export default KPIForecastDemo;
