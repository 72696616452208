import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import { apiUrl } from '@tra-sg/gatsby-theme-c360-portal/src/data/config';
import React from 'react';
import { faInfoCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { navigate } from "gatsby";


function FileUploaderPanel(props) {
  const { onChangeHandlerUploadFile, hasFiles } = props;

  const columnClass = hasFiles ? "column is-one-third" : "column is-one-third is-centered"
  const fileClass = hasFiles ? "file" : "file is-boxed"

  return (
    <div className='columns is-centered'>
      <div className={columnClass}>
        <div className={fileClass} style={{width: "100%"}}>
          <label className='file-label has-text-centered' style={{width: "100%"}}>
            <input className="file-input" type="file" name="file" onChange={onChangeHandlerUploadFile} multiple/>
            <span className='file-cta' style={{width: "100%"}}>
              <span className='file-label'>Add file(s)..</span>
            </span>
          </label>
        </div>
      </div>
    </div>
  )
}


class DatasetRegistration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoading: true,
      date: this.props.date,

      // user scope
      userScope: null,

      waitingForUpload: false,
      datasetUploaded: false,
      apiResponse: {},

      //permission selection
      selectedOption: [],

      // uploaded file information
      uploadedDataList: [],
      uploadfileList: [],

      // input dataset/table information
      // inputTableName: null,
      inputDatasetName: null,
      inputDatasetDescription: null,
      inputDatasetZone: "confidential",
      inputDatasetScope: null,
      inputDatasetPermission: null,
      inputDatasetNameValidation: null,
      inputPermissionValidation: null,
      tablesInformationDict: {},

      // dropdownList for unique dataset
      // and user/group permission
      uniqueDatasetList: this.props.uniqueDatasetList,
      groupUserList: null,

      // addingDataset validation
      addDatasetValidation: null,

      groupList: [],
      userList: [],
      datasetLists: [],

      // fetch s3Location
      uploadDestError: null,
      uploadDestLoading: null,

    };
  }

  componentDidMount() {
    this.fetchUserScope();
    this.fetchExistingDataset();
    this.fetchGroupUser();
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption, inputDatasetPermission: selectedOption });
    if (selectedOption) {

      if (selectedOption.length > 0) {
        this.setState({ inputPermissionValidation: null })
      } else {
        this.setState({ inputPermissionValidation: "Permissions required" })
      }
    } else {
      this.setState({ inputPermissionValidation: "Permissions required" })
    }
  };

  onChangeHandlerUploadFile = (event) => {
    // let uploadfileList = []
    // let uploadedDataList = []
    var { uploadfileList, uploadedDataList} = this.state;

    for (let file of event.target.files) {
      // files don't need to be read; they can just be sent as streams
      let uploadedData = {}
      uploadedData["table_name"] = file.name
      uploadedData["file"] = file
      uploadedDataList.push(uploadedData)
      uploadfileList.push(file.name)
    }

    this.setState({
      uploadedDataList: uploadedDataList,
      uploadfileList: uploadfileList
    }, () => { this. onDatasetInfoChange()})
    // call onDatasetInfoChange as a callback to setState
  }

  onClickEndAddDataset = (options) => {
    let onClickEndAddDataset = this.props.onEndAddingDataset
    if (onClickEndAddDataset) {
      onClickEndAddDataset(options)
    }
    this.clearState()
  }

  onClickHandlerAddDataset = () => {
    let { inputDatasetNameValidation, inputPermissionValidation, inputDatasetName, inputDatasetPermission, tablesInformationDict, uploadfileList} = this.state
    let invalid = false
    // Validate required fields
    this.onDatasetInfoChange()

    if (uploadfileList.length > 0) {
      // If uploaded file(s), check if there is any invalidation
      uploadfileList.forEach(file => {
        let table_name = file;
        if (tablesInformationDict[table_name]) {
          if (tablesInformationDict[table_name]["inputTableNameValidation"]) {
            invalid = true
          }
        }
      })
    }
    if (inputDatasetNameValidation || inputPermissionValidation || invalid || !inputDatasetName || !inputDatasetPermission) {
      this.setState({
        addDatasetValidation: "Dataset creation failed. Please correct your input"
      })
    } else {
      this.postRegisterDataset(true);
    }
  }

  clearState() {
    this.setState({
      inputDatasetName: null,
      inputDatasetDescription: null,
      inputDatasetScope: null,
      tablesInformationDict: null,
      uploadedDataList: [],
      uploadfileList: [],
      inputDatasetNameValidation: null,
      inputPermissionValidation: null,
      inputDatasetPermission: null,
      selectedOption: [],
      apiResponse: {},
      addDatasetValidation : null,
    })

    if (document.getElementById("dataset_name")) {
      document.getElementById("dataset_name").value = null
    }
  }

  getPermissionObjFromSelectedOptions() {
    let { selectedOption } = this.state;

    let permissions = { groups: [], users: [] }

    selectedOption.forEach((v) => {
      if (v.value.startsWith('group/')) {
        permissions.groups.push(v.label);
      } else if (v.value.startsWith('group/')) {
        permissions.users.push(v.label);
      }
    })

    return permissions;
  }

  postRegisterDataset(real_request) {
    // send a POST request to API to create dataset with the selected options
    if (real_request) {
      this.setState({waitingForUpload: true, uploadDestError: null, uploadDestLoading: true});
    }

    let {
      inputDatasetName,
      inputDatasetDescription,
      inputDatasetScope,
      tablesInformationDict,
      uploadedDataList,
    } = this.state;

    const form = new FormData();

    // handle dataset groups. If its not common, it should be user/username
    let datasetGroups;
    if (inputDatasetScope == "common") {
      datasetGroups = ['c360_sandbox', inputDatasetScope];
    } else {
      datasetGroups = ['users', inputDatasetScope];
    }

    let payload = {
      name: inputDatasetName,
      description: inputDatasetDescription,
      groups: datasetGroups,
      permissions: this.getPermissionObjFromSelectedOptions(),
      table_details: tablesInformationDict,
      dry_run: !real_request,
    }

    form.append('json', JSON.stringify(payload));

    uploadedDataList.forEach(
      (e) => {
        form.append(e.table_name, e.file);
      }
    )

    callApi(
      "dataset/initialize",
      (result) => {
        this.setState({ uploadDestLoading: false })
        if (real_request) {
          this.setState({ waitingForUpload: false, datasetUploaded: true, apiResponse: result });
          this.clearState()
          setTimeout(async () => {
            this.onClickEndAddDataset({
              refresh: true,
              activeUrl: `/dataset/users/${inputDatasetScope}/${inputDatasetName}`,
            });
          }
          , 500);
        } else{
          this.setState({ apiResponse: result });
        }
      },
      (error) => {
        this.setState({ uploadDestLoading: false})
        if(real_request) {
          this.setState({
            waitingForUpload: false,
            addDatasetValidation: "Error adding dataset...",
            uploadDestError: error
          })
        }
      },
      {
        method: 'post',
        body: form,
      }
    )
  }

  validateTableDatasetName(name) {
    var format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (name == "") {
      return "Cannot be blank"
    } else if(format.test(name)){
      return "Only '_' is allowed for special characters"
    } else {
      // kind of hacky, should be changed later
      if (name.split("__").length > 1) {
        return "Only single '_' is allowed"
      } else {
        return null
      }
    }
  }

  onDatasetInfoChange = () => {
    const { userScope } = this.state;

    let newState = {}

    // Dataset information
    if (document.getElementById("dataset_share_scope")) {
      Object.assign(newState, {
        inputDatasetScope: userScope,
      })
    }
    if (document.getElementById("dataset_name")) {
      Object.assign(newState, {
        inputDatasetName: document.getElementById("dataset_name").value,
        inputDatasetNameValidation: this.validateTableDatasetName(document.getElementById("dataset_name").value)
      })
    }
    if (!this.state.selectedOption) {
      Object.assign(newState, {
        inputPermissionValidation: "Permissions required"
      });
    }

    // Tables information
    let tableNameList = this.state.uploadfileList
    let singleTableInformationDict = {}
    let tablesInformationDict = {}
    let table_name
    tableNameList.forEach(file => {
      table_name = file;
      // reset
      singleTableInformationDict = {}
      if (document.getElementById(`table_structure_${table_name}`)) {
        singleTableInformationDict["table_structure"] = document.getElementById(`table_structure_${table_name}`).checked
        if (document.getElementById(`upload_table_name_${table_name}`)) {
          singleTableInformationDict["upload_table_name"] = document.getElementById(`upload_table_name_${table_name}`).value
          if(document.getElementById(`table_structure_${table_name}`).checked) {
            singleTableInformationDict["inputTableNameValidation"] = this.validateTableDatasetName(document.getElementById(`upload_table_name_${table_name}`).value)
          } else if (document.getElementById(`upload_table_name_${table_name}`).value == "") {
            singleTableInformationDict["inputTableNameValidation"] = "Cannot be blank"
          } else {
            singleTableInformationDict["inputTableNameValidation"] = null
          }
        }
      }
      if (document.getElementsByName(`dataset_zone_${table_name}`)) {
        for (var i = 0, length = document.getElementsByName(`dataset_zone_${table_name}`).length; i < length; i++) {
          if (document.getElementsByName(`dataset_zone_${table_name}`)[i].checked) {
            singleTableInformationDict["zone"] = "source_" + document.getElementsByName(`dataset_zone_${table_name}`)[i].value
            // only one radio can be logically checked, don't check the rest
            break;
          }
        }
      }
      tablesInformationDict[table_name] = singleTableInformationDict
    })
    Object.assign(newState, {
      tablesInformationDict: tablesInformationDict
    })
    this.setState(newState, () => {
      this.postRegisterDataset(false);
      // dry run request
      // must be called as a callback from setState to keep the api call
      // from using old values
    });

  }

  getGroupUserList() {
    let { groupList, userList } = this.state;

    return [...groupList, ...userList];
  }

  fetchGroupUser() {
    // fetch list of user/group
    callApi(
      "entity/group/list",
      (result) => {
        let groups = result.data;
        let groupList = Object.entries(groups).map((
          [k, v], i) => {
            return { value: 'group/' + k, label: k};
          }
        );
        this.setState({ groupList });
      },
      (error) => {console.log("Error fetching group list", error)},
    )

    callApi(
      "entity/user/list",
      (result) => {
        let users = result.data;
        let userList = Object.entries(users).map((
          [k, v], i) => {
            return { value: 'user/' + k, label: k};
          }
        );
        this.setState({ userList });
      },
      (error) => {console.log("Error fetching user list", error)},
    )
  }

  fetchUserScope() {
    callApi(
      "entity/user/scope",
      (result) => {
        let userScope = result.scope;
        this.setState({ userScope, isLoading: false });
      },
      (error) => {console.log("Error fetching user scope", error)},
    )
  }

  fetchExistingDataset() {
    callApi(
      "dataset/list",
      (result) => {
        let datasetLists = result.datasets;
        this.setState({ datasetLists: datasetLists, isLoading: false });
      },
      (error) => {console.log("Error fetching dataset lists", error)},
    )
  }

  checkifDatasetExists() {
    // fetch list of datasets and their description
    let { datasetLists, userScope } = this.state
    let dataset_list = []
    let permissions = {}

    if (datasetLists.length > 0) {
      datasetLists.map(dataset => {
        dataset_list.push({
          dataset_name: dataset.datapackage.name,
          description: dataset.datapackage.description,
          group: dataset.groups
        })
      })
    }

    let datasetExist = null
    let datasetDescription = null
    let dataset_group = ["users", userScope]

    for (var i = 0; i < dataset_list.length; i++) {
      if (dataset_list[i].group.every(function(value, index) { return value == dataset_group[index]})) {
        if (dataset_list[i].dataset_name == document.getElementById("dataset_name").value) {
          datasetExist = true,
          datasetDescription = dataset_list[i].description
          break;
        }
      }
    }

    if (datasetExist) {
      document.getElementById("dataset_description").value = datasetDescription
      document.getElementById("dataset_description").setAttribute("disabled", "true")
      document.getElementById("dataset_description").className = ("textarea")
      // document.getElementById("dataset_permission").setAttribute("isDisabled", "true")
      this.setState({
        inputDatasetDescription: datasetDescription
      })
    } else {
      // document.getElementById("dataset_description").value = null
      document.getElementById("dataset_description").removeAttribute("disabled")
      document.getElementById("dataset_description").className = ("textarea is-primary")
      // document.getElementById("dataset_permission").removeAttribute("isDisabled")
    }
  }

  renderInvalidateTableName(table_id) {
    let { tablesInformationDict } = this.state
    if (tablesInformationDict[table_id]) {
      return tablesInformationDict[table_id]["inputTableNameValidation"]
    } else return null
  }

  renderAddDataset() {
    const {
      uploadedData,
      userScope,
      uploadfileList,
      inputDatasetName,
      inputDatasetScope,
      selectedOption,
      uniqueDatasetList,
      inputDatasetNameValidation,
      inputPermissionValidation,
      addDatasetValidation,
      tablesInformationDict } = this.state;

    let uploadedFileListDiv
    let uploadedFileListBody = []
    let shareScope


    if (uploadfileList.length > 0) {
      uploadfileList.forEach(file => {
        let table_name = file;
        uploadedFileListBody.push(
          <tr>
            <td><input className="input is-small is-primary" type="text" name={`upload_table_name_${table_name}`} id={`upload_table_name_${table_name}`} defaultValue={table_name} onChange={this.onDatasetInfoChange}/>
              <span className="required">{this.renderInvalidateTableName(table_name)}</span>
            </td>
            <td><input type="radio" id={`confidential_${table_name}`} name={`dataset_zone_${table_name}`} value="confidential" defaultChecked onClick={this.onDatasetInfoChange} style={{ marginTop: '9px' }}></input></td>
            <td><input type="radio" id={`restricted_${table_name}`} name={`dataset_zone_${table_name}`} value="restricted" onClick={this.onDatasetInfoChange} style={{ marginTop: '9px' }}></input></td>
            <td><input type="radio" id={`public_${table_name}`} name={`dataset_zone_${table_name}`} value="public" onClick={this.onDatasetInfoChange} style={{ marginTop: '9px' }}></input></td>
            <td><input type="checkbox" id={`table_structure_${table_name}`} name={`table_structure_${table_name}`} onClick={this.onDatasetInfoChange} style={{ marginTop: '9px' }}></input></td>
          </tr>
        )
      })
      uploadedFileListDiv = (
        <table className="table is-bordered is-fullwidth is-size-7">
          <thead>
            <tr>
              <td style={{ color: 'black', backgroundColor: '#f1f1f1' }}></td>
              <td style={{ color: 'black', backgroundColor: '#f1f1f1' }}>confidential
                <span className="icon is-small" data-tooltip="Contains confidential information such as PII data">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
              </td>
              <td style={{ color: 'black', backgroundColor: '#f1f1f1' }}>restricted
                <span className="icon is-small" data-tooltip="Can be shared within users">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
              </td>
              <td style={{ color: 'black', backgroundColor: '#f1f1f1' }}>public
                <span className="icon is-small" data-tooltip="Can be shared publicly">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
              </td>
              <td style={{ color: 'black', backgroundColor: '#f1f1f1' }}>is structured
                <span className="icon is-small" data-tooltip="The data is structured/tabular. I.e csv, xls files">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
              </td>
            </tr>
          </thead>
          <tbody>
              { uploadedFileListBody }
          </tbody>
        </table>
      )
    }

    return (
      <div className="has-text-centered">
        <div className="is-vcentered" style={{ margin: 'auto' }}>
        <div className="container">
          <div className="row">
            <div className="has-text-left">
              <div className="field has-addons">
                <p className="control">
                  <text className="" id="dataset_share_scope" name="dataset_share_scope" style={{ fontWeight: "bold", fontSize: "26px", paddingTop: "20px" }}>
                    {userScope}
                  </text><text style={{ fontWeight: "bold", fontSize: "26px", paddingTop: "20px" }} > &nbsp; / &nbsp; </text>
                </p>
                <p className="control">
                  <input className="input is-primary" type="text" placeholder="Dataset" id="dataset_name" name="dataset_name" onChange={this.onDatasetInfoChange} required/>
                </p>
                <p className="control">
                  <span className="required"> &nbsp; * &nbsp;</span>
                  <span className="icon is-small has-tooltip-multiline has-tooltip-bottom" data-tooltip="Select the dataset or create new dataset. Numeric, normal characters or a single '_' allowed">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                  &nbsp; &nbsp;
                  <span className="required"> {inputDatasetNameValidation}</span>
                </p>
              </div>
              <label><strong> Description: &nbsp;</strong></label>
              <span className="icon is-small has-tooltip-multiline" data-tooltip="Description for the dataset, markdown supported">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              <br></br>
              <textarea className="textarea is-primary" id="dataset_description" name="dataset_description" rows="2" cols="30" onChange={this.onDatasetInfoChange}></textarea>
              <br></br>
              <label><strong>Permissions:</strong></label>
              <span className="required"> &nbsp; * &nbsp;</span>
              <span className="icon is-small has-tooltip-multiline" data-tooltip="Add group users that can access this dataset">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              &nbsp; &nbsp;
              <span className="required"> {inputPermissionValidation}</span>
              <br></br>
              <Select options={this.getGroupUserList()} value={selectedOption} isMulti id="dataset_permission" name="dataset_permission" onChange={this.handleChange}/>
            </div>
            <hr></hr>
            <div className="col-md-6">
                <form method="post" action="#" id="#">
                  <div className="form-group">
                    <label>Upload Your File </label>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
      <div>
        { uploadedFileListDiv }
        <FileUploaderPanel hasFiles={uploadedFileListDiv} onChangeHandlerUploadFile={this.onChangeHandlerUploadFile} />
        { this.renderSimulatedS3Path() }
        <div className="field is-grouped">
          <div className="control">
            { this.renderSubmitButton() }
            &nbsp; &nbsp;
            <span className="required"> {addDatasetValidation}</span>
          </div>
        </div>
      </div>
      </div>
    );
  }

  renderSubmitButton() {
    let { waitingForUpload, datasetUploaded } = this.state;

    if (waitingForUpload) {
      return (
        <div>
          <button className="button is-light is-loading" onClick={this.onClickHandlerAddDataset}>
            Create dataset
          </button>
          <p className="is-size-7 is-italic">
            Your dataset is being created...
          </p>
        </div>
      )
    } else if (datasetUploaded) {
      return (
        <div>
          <button className="button is-success" onClick={this.onClickHandlerAddDataset}>
            Dataset uploaded <FontAwesomeIcon icon={faCheck} />
          </button>
        </div>
      )
    } else {
      return (
        <div>
          <button className="button is-success" onClick={this.onClickHandlerAddDataset}>
            Create dataset
          </button>
          &nbsp; &nbsp;
          <button className="button is-warning" onClick={this.onClickEndAddDataset}>
            Cancel
          </button>
        </div>
      )
    }
  }

  renderSimulatedS3Path() {
    let { apiResponse, uploadDestError, uploadDestLoading } = this.state;

    if (uploadDestError) {
      return (
        <div>
          <hr></hr>
          <h5>Upload destination(s) &nbsp;
            <span className="icon is-small has-tooltip-multiline" data-tooltip="Location in S3">
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          </h5>
          Error fetching Uploaded S3 Location. Reason: {uploadDestError.message}
        </div>
      );
    }

    if (uploadDestLoading) {
      return (
        <div className="columns is-centered">
          <div className="column has-text-centered is-2">
            <div className="iframe-holder" />
          </div>
        </div>
      );
    }

    if (!apiResponse.details) {
      // no details in response, dont show anything
      return;
    }

    let paths = apiResponse.details.tables.map(element => element.s3_path );
    let paths_div = paths.map(s3_path => (
      <div>
        { s3_path }
      </div>
    ))

    if (apiResponse.details) {
      if ((apiResponse.details.tables || []).length > 0) {
        return (
          <div>
            <hr></hr>
            <h5>Upload destination(s) &nbsp;
              <span className="icon is-small has-tooltip-multiline" data-tooltip="Location in S3">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
            </h5>
            {paths_div}
          </div>
        )
      }
    }
  }

  renderError() {
    const messageClass = 'is-danger';
    const errorReason = 'It seems like something went wrong with the Dataset Registration.';

    const { error } = this.state;

    return (
      <div className="columns is-full">
        <article className={`message ${messageClass}`}>
          <div className="message-body">
            { errorReason }
            <br />
            <i>
              {' '}
              Error:
              { error.message }
              {' '}

            </i>
          </div>
        </article>
      </div>
    );
  }

  render() {
    const { error, isLoading, waitingForUpload } = this.state;

    if (error) {
      return (
        <div className="section">
          { this.renderError() }
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="columns is-centered">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      );
    }

    return (
      <div>
          {this.renderAddDataset()}
      </div>
    );
  }
}

export default DatasetRegistration;
