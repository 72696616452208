// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-pages-404-js": () => import("./../../../node_modules/@tra-sg/gatsby-theme-c360-portal/src/pages/404.js" /* webpackChunkName: "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-pages-404-js" */),
  "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-pages-logout-index-js": () => import("./../../../node_modules/@tra-sg/gatsby-theme-c360-portal/src/pages/logout/index.js" /* webpackChunkName: "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-pages-logout-index-js" */),
  "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-pages-tags-index-js": () => import("./../../../node_modules/@tra-sg/gatsby-theme-c360-portal/src/pages/tags/index.js" /* webpackChunkName: "component---node-modules-tra-sg-gatsby-theme-c-360-portal-src-pages-tags-index-js" */),
  "component---src-pages-campaigns-js": () => import("./../../../src/pages/campaigns.js" /* webpackChunkName: "component---src-pages-campaigns-js" */),
  "component---src-pages-customers-js": () => import("./../../../src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-datasets-js": () => import("./../../../src/pages/datasets.js" /* webpackChunkName: "component---src-pages-datasets-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-labs-experiment-js": () => import("./../../../src/pages/labs/experiment.js" /* webpackChunkName: "component---src-pages-labs-experiment-js" */),
  "component---src-pages-labs-index-js": () => import("./../../../src/pages/labs/index.js" /* webpackChunkName: "component---src-pages-labs-index-js" */),
  "component---src-pages-models-js": () => import("./../../../src/pages/models.js" /* webpackChunkName: "component---src-pages-models-js" */),
  "component---src-pages-notification-settings-js": () => import("./../../../src/pages/notification/settings.js" /* webpackChunkName: "component---src-pages-notification-settings-js" */),
  "component---src-pages-sales-js": () => import("./../../../src/pages/sales.js" /* webpackChunkName: "component---src-pages-sales-js" */),
  "component---src-pages-status-docs-js": () => import("./../../../src/pages/status/docs.js" /* webpackChunkName: "component---src-pages-status-docs-js" */),
  "component---src-pages-status-index-js": () => import("./../../../src/pages/status/index.js" /* webpackChunkName: "component---src-pages-status-index-js" */),
  "component---src-pages-status-infra-js": () => import("./../../../src/pages/status/infra.js" /* webpackChunkName: "component---src-pages-status-infra-js" */),
  "component---src-pages-status-overview-js": () => import("./../../../src/pages/status/overview.js" /* webpackChunkName: "component---src-pages-status-overview-js" */),
  "component---src-pages-status-pipeline-js": () => import("./../../../src/pages/status/pipeline.js" /* webpackChunkName: "component---src-pages-status-pipeline-js" */),
  "component---src-pages-status-portal-js": () => import("./../../../src/pages/status/portal.js" /* webpackChunkName: "component---src-pages-status-portal-js" */),
  "component---src-pages-status-users-js": () => import("./../../../src/pages/status/users.js" /* webpackChunkName: "component---src-pages-status-users-js" */),
  "component---src-pages-surveys-js": () => import("./../../../src/pages/surveys.js" /* webpackChunkName: "component---src-pages-surveys-js" */)
}

