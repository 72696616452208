import { callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import ExperimentResult from '@tra-sg/gatsby-theme-c360-portal/src/components/LabGallery/ExperimentResult';
import BaseGallery from '../BaseGallery';


function ExperimentViewSingle(props) {
    const { experimentId } = props;
    const { isLoading, isError, data, error } = useQuery(
        [`model/exp_train/${experimentId}`, {}],
        async () => await callApiWithResult(`model/exp_train/${experimentId}`),
        { staleTime: 1000 * 5, }
    )

    if (isLoading) {
        return (
          <div className="columns is-centered">
            <div className="column has-text-centered is-10">
              <div className="iframe-holder" />
            </div>
          </div>
        );
    }

    if (isError) (
      <div className='container'>
          ERROR: {error}
      </div>
  )

  console.log("DATA RECEIVED", data);
  let status = "IN_PROGRESS";  // or "SUCCESS" or "FAILED"

  const statusMessage = {
    SUCCESS: "The experiment has completed.",
    FAILURE: "The experiment failed...",
    IN_PROGRESS: "Experiment is in progress..",
  }

  const statusStyle = {
    SUCCESS: "message is-success",
    FAILURE: "message is-danger",
    IN_PROGRESS: "message is-warning",
  }

  var model_data;
  if (data.result) {
    if (data.result.models) {
      model_data = {model_list: Object.values(data.result.models)}
    }
  }

  return (
      <div>
          <div className='container'>
            <div className={statusStyle[data.status]}>
              <div className='message-body'>
                {statusMessage[data.status]}
              </div>
            </div>
            <ExperimentResult
              dataset={data.body.data_source.dataset}
              zone={data.body.data_source.zone}
              group={data.body.data_source.groups}
              table={data.body.data_source.table}
              status={data.status}
              experimentName={data.body.experiment_name}
              label={data.body.label}
              model_data={model_data}
            />
          </div>
      </div>
  )
}

function ExperimentGalleryItem(props) {
  const { Name, body, status } = props;

  const statusStyle = { SUCCESS: "green", FAILURE: "red", IN_PROGRESS: "yellow"};
  const activeStyle = statusStyle[status];

  return (
    // <a href={`/labs/experiment/${Name}`} >
        <div className='box' style={{borderLeftWidth: '1em', borderLeftColor: activeStyle, borderLeftStyle: "solid"}}>
            <p className='subtitle'>{Name}</p>
            <p className=''>{body.description}</p>
            <p className='has-text-right is-italic is-size-7' style={{color: 'gray'}}>
              {body.model_type.toUpperCase()}
            </p>
        </div>
    // </a>
  )
}

function ExperimentGalleryItemPage(props) {
  const { Name, body } = props;

  return (
    <div className='container'>
        <ExperimentViewSingle experimentId={Name}/>
    </div>
  )
}

export default function ExperimentView(props) {
    // labs/experiments/
    // or
    // labs/experiments/newexp

    let url = window.location.href;
    let urlToken = url.split("labs/experiment/")
    if (urlToken.length >= 2) {
      if (urlToken[1] > "") {
        let experimentId = urlToken[1];
        return (<ExperimentViewSingle experimentId={experimentId} />)
      }
    }
    return (
      <>
        <BaseGallery
          name="Experiment"
          query={{
            key: ["model/exp_train/", {}],
            method: async () => await callApiWithResult(`model/exp_train/`)
          }}
          mainKey={"items"}
          layout="grid"
          CatalogItemRender={ExperimentGalleryItem}
          CatalogPageRender={ExperimentGalleryItemPage}
          sortFieldMapping={{
            "Name": "Name",
            "Description": "body.description",
            "Status": "status",
          }}
        />
        {/*  */}
      </>
    );
}
