import React from 'react';
import BaseMetric from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseMetric';
import { Link } from 'gatsby';
import Feed from '@tra-sg/gatsby-theme-c360-portal/src/components/Feed';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';


class UserOverview extends BaseMetric {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            error: null,
            date: null,
            data: [
                {
                    message: "Login: user@tra.sg",
                    timestamp: 123456789,
                }
            ],
            page: 0,
        }

        this.logPerPage = 3;
    }

    getStatus() {
        if (this.state.isLoading) {
          return "loading";
        }
        return 'pass';
    }

    getTitle() {
        if (this.props.title) {
          return (
            <Link to="/status/users"> {this.props.title} </Link>
          )
        } else {
          return "Recent Logins";
        }
    }

    getApiPath() {
        return "status/user/lastlogins";
    }

    renderMetric() {
        let data = this.state.data;

        let feedData = data.map((e) => {
            return {
                title: e.message,
                datetime: moment.unix(e.timestamp/1000).format("MMM D[,] YYYY hh[:]mma [(UTC]Z[)]"),
                icon: faUser,
            }
        })

        return (
            <div>
                <Feed heading={this.props.title ? "Recent Logins" : null} items={feedData} />
            </div>
        )
    }
}


export default UserOverview;
