import React from 'react';
import BaseGallery from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseGallery';
import { callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import IframeComponent from '@tra-sg/gatsby-theme-c360-portal/src/components/IframeComponent'
import QuickLook from '@tra-sg/gatsby-theme-c360-portal/src/components/QuickLook';


function DashboardItem(props) {
  const { name, title, path, description, tags, group, quicklook } = props;
  const { subtitle, apiPath, qlX, qlY, chartType } = quicklook;
  return (
    <div>
      <div className='quick-look-gallery'>
        <QuickLook
          title={title}
          subtitle={subtitle}
          // targetUrl={slug}
          chartType={chartType}
          apiPath={apiPath}
          X={qlX}
          Y={qlY}
          // groupFields={qlGroup}
        />
      </div>
    </div>
  )
}


function DashboardItemPage({ name, path }) {
  return (
    <IframeComponent
      tenant="acme"
      stage="prod"
      dashboard_slug={name}
      dashboard_url={path}
    />
  )
}


export default function DashboardGallery({ group }) {
  return (
    <BaseGallery
      name={group}
      query={{
        key: [`dashboard`, {}],
        method: async () => await callApiWithResult(`dashboard/?group=${group}`)
      }}
      mainKey={"dashboards"}
      layout="grid"
      CatalogItemRender={DashboardItem}
      CatalogPageRender={DashboardItemPage}
      sortFieldMapping={{
        "name": "name",
      }}
    />
  )
}
