import React, { useState } from 'react';
import { useQuery } from 'react-query';
import LabTablePreview from '@tra-sg/gatsby-theme-c360-portal/src/components/LabGallery/LabTablePreview';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import { faDiceD6, faCogs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function ExperimentResult(props) {
    const {
        dataset,
        zone,
        group,
        table,
        status,
        experimentName,
        label,
        model_data,
        sortBy,  // if not given, default to "Accuracy"
    } = props;

    let finalSortBy = sortBy || "Accuracy";
    let sortedModelData = sortModelData(model_data, finalSortBy)

    return (
        <div>
            <div>
                <ExperimentParams
                    dataset={dataset}
                    zone={zone}
                    group={group}
                    table={table}
                    label={label}
                    experimentName={experimentName}
                />
            </div>
            <hr/>
            <div>
                <ExperimentResultDetail
                    dataset={dataset}
                    zone={zone}
                    group={group}
                    table={table}
                    status={status}
                    model_data={sortedModelData}
                    experimentName={experimentName}
                />
            </div>
        </div>
    )
}


function ExperimentParams(props) {
  const { dataset, zone, group, table, label, experimentName } = props;

  return (
    <div>
      <div>
        <p className='title'>{experimentName}</p>
      </div>
      <br/>
      <div>
        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field" disabled={true}>
              <label className="subtitle is-6">Dataset</label>
              <input
                className="input"
                type="text"
                placeholder="Dataset"
                value={dataset}
                disabled={true}
              />
            </div>
            <div className="field">
              <label className="subtitle is-6">Table</label>
              <input
                className="input"
                type="text"
                placeholder="Table"
                value={table}
                disabled={true}
              />
            </div>
            <div className="field">
              <label className="subtitle is-6">Target</label>
              <input
                className="input"
                type="text"
                placeholder="Table"
                value={label}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ExperimentResultDetail(props) {
  const { dataset, zone, group, table, } = props;
  const { status, model_data, experimentName } = props;
  return (
    <div>
      <Tabs forceRenderTabPanel={true}>
        <TabList>
          <Tab>Models</Tab>
          <Tab>Source Data</Tab>
        </TabList>

        <TabPanel>
          <div>
            <ExpModelListView model_data={model_data} experimentName={experimentName} />
          </div>
        </TabPanel>
        <TabPanel>
          <LabTablePreview
            dataset_id={dataset} table_id={table} zone_id={zone} group_id={group}
          />
        </TabPanel>

      </Tabs>
    </div>
  )
}

function ExpModelListView(props) {
  const { model_data, experimentName } = props;

  const noModelComponent = (
    <div className='has-text-centered'>
      <FontAwesomeIcon
        icon={faCogs}
        style={{ fontSize: '5em', color: 'lightgrey', margin: '20px' }}
      />
      <br/>
      <p>There are currently no model available. Please come back later.</p>
    </div>
  )

  if (model_data == null) return noModelComponent;
  if (model_data.model_list == null) return noModelComponent;
  if (model_data.model_list.length == 0) return noModelComponent;

  const [ selectedModel, setSelectedModel ] = useState(model_data.model_list[0].name);

  console.log("modeld ata", model_data);

  return (
    <div>
      <table className='table is-bordered is-fullwidth is-size-6'>
        <thead>
          <tr>
            { ["Name", "Accuracy", "AUC", "Precision", "Recall", "F1"].map((e) => (<th>{e}</th>)) }
          </tr>
        </thead>
        <tbody>
          { model_data.model_list.map((e) => (
            <ModelMetricRow
              data={e}
              experimentName={experimentName}
              selectedModel={selectedModel}
              setSelectedModel={setSelectedModel}/>
          ))}
        </tbody>
      </table>
      <div className='columns'>
        <div className='column is-two-thirds'>
          <ModelPlots model_data={model_data} selectedModel={selectedModel} />
        </div>
        <div className='column is-one-third'>
          <ModelSuggestion />
        </div>
      </div>
    </div>
  )
}

function ModelMetricRow(props) {
  const { data, selectedModel, setSelectedModel, experimentName } = props;

  const style = data.name == selectedModel ? "is-selected" : "";

  return (
    <tr className={style} >
      <td onClick={() => setSelectedModel(data.name)}>{data.name.replace(`${experimentName}/`, "")}</td>
      { ["Accuracy", "AUC", "Prec.", "Recall", "F1"].map((e) => (<td>{data.eval[e]}</td>)) }
    </tr>
  )
}

function PlotModal(props) {
  const { url, setModalUrl } = props;

  return (
    <div className={'modal' + (url > '' ? ' is-active' : '')}>
      <div className='modal-background' onClick={() => setModalUrl(null)} />
      <div className='modal-content'>
        <div className='image'>
          <img src={url} />
        </div>
      </div>
      <div className='modal-close is-large' onClick={() => setModalUrl(null)} />
    </div>
  )
}

function ModelPlots(props) {
  const { model_data, selectedModel } = props;

  const { isLoading, isError, data, error } = useQuery(
    [`model/${selectedModel.toLowerCase()}/plots`, {}],
    async () => await callApiWithResult(`model/${selectedModel.toLowerCase()}/plots`)
  )
  const [modalUrl, setModalUrl] = useState(null);

  if (model_data == null) return "";

  if (isLoading) {
    return (
      <div className="columns is-centered">
        <div className="column has-text-centered is-10">
          <div className="iframe-holder" />
        </div>
      </div>
    );
  }

  if (error) return (<div>{error}</div>);

  var dataSelected;
  if (model_data) {
    model_data.model_list.forEach((e) => {
      if (e.name == selectedModel) {
        dataSelected = e;
      }
    })
  }

  console.log("plot urls", data.plot_urls)

  if(data) {
    if(data.plot_urls.length > 0) {
      return (
        <div>
          <PlotModal url={modalUrl} setModalUrl={setModalUrl} />
          <div>
            <p className='is-size-5'>Model Plots</p>
            <hr/>
          </div>
          <div className="columns is-multiline">
            {/* <ModelAccuracyRadial data={dataSelected}/> */}
            {
              data.plot_urls.map(url => (
                <div className="column is-half" onClick={() => setModalUrl(url)}>
                  <p className='subtitle is-size-5'>
                    {getFilenameFromUrl(url)}
                  </p>
                  <img src={url} />
                </div>
              ))
            }
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div>
            <p className='is-size-5'>Model Plots</p>
            <hr/>
            <p className='is-italic'>No plots found for this model.</p>
          </div>
        </div>
      )
    }
  }

  return (<div></div>);
}

function ModelSuggestion(props) {
  const suggestions = [
    "The data seems to have an imbalance in positive and negative classes. Fixing this imbalance may improve accuracy.",
    "The feature `cabin` seems to have a lot of missing values. Consider removing this feature for better performance.",
    "The feature `fare` and `Pclass` seems to be highly corellated. Consider merging them into one feature, or selecting only one.",
    "This model has had parameter tuning for the following parameter space: ...",
  ]

  return (
    <div>
      <div>
        <p className='is-size-5'>Next Steps</p>
        <hr/>
      </div>
      { suggestions.map((e) => (<SingleSuggestion message={e} />))}
    </div>
  )
}

function SingleSuggestion(props) {
  const { message } = props;

  return (
    <div className='message is-info'>
      <div className='message-body'>
        {message}
      </div>
    </div>
  )
}


/* Utility Functions */

function getFilenameFromUrl(url) {
  let base_url_token = url.split("?")[0].split("/");
  let filename = base_url_token[base_url_token.length - 1].split(".")[0]
  return filename.replace("%20", " ");
}

function sortModelData(model_data, sortBy) {
  console.log("before sort", model_data)
  if (model_data == null) return model_data;
  let newModelData = { ...model_data };
  newModelData.model_list = newModelData.model_list.sort((a, b) => {
    if (a.eval[sortBy] < b.eval[sortBy]) return 1;
    if (a.eval[sortBy] > b.eval[sortBy]) return -1; // inverse sort
    return 0
  })
  console.log("after sort", newModelData)
  return newModelData;
}
