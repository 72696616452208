import dashboardIcon from '@tra-sg/gatsby-theme-c360-portal/src/icons/dashboard-icon-00.svg';
import behaviorIcon from '@tra-sg/gatsby-theme-c360-portal/src/icons/behavioral-00.svg';
import campaignIcon from '@tra-sg/gatsby-theme-c360-portal/src/icons/campaign-dashboards-00.svg';
import clvIcon from '@tra-sg/gatsby-theme-c360-portal/src/icons/clv-00.svg';
import pipelineIcon from '@tra-sg/gatsby-theme-c360-portal/src/icons/pipelines-00.svg'
import p2bIcon from '@tra-sg/gatsby-theme-c360-portal/src/icons/p2b-00.svg'
import labsIcon from '@tra-sg/gatsby-theme-c360-portal/src/icons/labs-00.svg'

let presets = {
    dashboardIcon,
    behaviorIcon,
    campaignIcon,
    clvIcon,
    pipelineIcon,
    p2bIcon,
    labsIcon,
};

export default presets
