import React, { Component } from 'react';
import { Link } from "@reach/router"
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};


function try_float(val) {
  return parseFloat(val) || val
}


export default class ModelSandbox extends React.Component {
    constructor(props) {
      /*
        Excpeted props:
        - modelId
        - model_type
       */
      super(props);

      var featureFields = {};
      props.columns.forEach(e => {
        featureFields[e] = null;
      })

      this.state = { 
        error: null,
        modelId: props.modelId,
        model_type: props.model_type || "CLASSIFICATION",  // "CLASSIFICATION" or "REGRESSION"
        label: null,
        score: null,
        featureFields: featureFields,
        isLoading: false,
      };

      this.renderFeatureField = this.renderFeatureField.bind(this);
      this.handleFeatureFieldChange = this.handleFeatureFieldChange.bind(this);
      this.handlePredictButton = this.handlePredictButton.bind(this);
    }

    handleFeatureFieldChange(event) {
      var { featureFields } = this.state;
      featureFields[event.target.id] = try_float(event.target.value);
      this.setState({ featureFields: featureFields });
    }

    handlePredictButton() {
      let { modelId, featureFields } = this.state;

      let body = {
        data: [featureFields],
      }

      const callApiUrl = `model/${modelId}/predict`

      this.setState({isLoading: true});

      callApi(
        callApiUrl,
        (result) => {
          console.log("RESULT OF PREDICT", result)
          this.setState({
            score: result.predictions[0]["Score"], 
            label: result.predictions[0]["Label"], 
            isLoading: false
          })
        },
        (error) => this.setState({ error, isLoading: false }),
        {
          method: 'post',
          body: JSON.stringify(body),
          headers: {'Content-Type': 'application/json'}
        }
      )
    }

    renderTitle() {
      return(
        <div className="subtitle">Model Sandbox</div>
      )
    }

    renderFeatureField(featureName) {
      let { featureFields } = this.state;
      return (
        <div className="field column is-one-third">
          <p className="control">
            {featureName}
            <input id={featureName} className="input" type="text" value={featureFields[featureName]} onChange={this.handleFeatureFieldChange}/>
          </p>
        </div>
      )
    }

    renderFormPanel() {
      var { columns } = this.props;

      return (
        // <div style={{"border-right": "2px solid gray"}}>
        <div>
          <div className="columns is-multiline">
            {columns.map(this.renderFeatureField)}
          </div>
          
        </div>
      )
    }

    renderClassificationPie() {
      var { score } = this.state;
      var data = [
        {name: "Positive", value: score},
        {name: "Negative", value: 1-score},  // assume score < 1
      ]

      return (
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      );
      
    }

    renderResultText() {
      var { error, isLoading, model_type, label, score } = this.state;

      console.log("MODEL TYPE", model_type)

      if (isLoading) {
        return (
          <div>
            <div className="iframe-holder" />
          </div>
        );
      }

      if (error) {
        // return `Error: ${error}`
        return (
          <div className="card is-danger">
            Error: {error}
          </div>
        )
      } else if (score == null) {
        return ""
      } else {
        if (model_type == "CLASSIFICATION") {
          return(
            <div>
              <br/>
              <p className="is-size-3">Label: {label}</p>
              <p>Score: {score}</p>
            </div>
          )
        } else {
          return(
            <div>
              <p>Score: {score}</p>
            </div>
          )
        }
        // return `Result: ${score}`
      }
    }

    renderResultPanel() {
      var { error, score, modelType } = this.state;

      return (
        <div>
          <div className="has-text-centered">
            <a 
              className="button is-info" style={{width: '100%'}}
              onClick={this.handlePredictButton}
            >Predict</a>
          </div>
          <div>
            { modelType == "CLASSIFICATION" ? this.renderClassificationPie() : ""}
          </div>
          <div className="has-text-centered is-vcentered">
            {this.renderResultText()}
          </div>
        </div>
      )

    }

    render() {
      return (
        <div>
          <div>
            {this.renderTitle()}
            <hr/>
          </div>
          <div className="columns">
            <div className="column is-two-thirds">
              {this.renderFormPanel()}
            </div>
            <div className="column is-one-third">
              {this.renderResultPanel()}
            </div>

          </div>
        </div>
      )
    }
}