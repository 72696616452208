import ModelBrowser from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser';
import QuickLook from '@tra-sg/gatsby-theme-c360-portal/src/components/QuickLook';
import IframeComponent from '@tra-sg/gatsby-theme-c360-portal/src/components/IframeComponent';
import SalesForecastFeatureBreakdown from '@tra-sg/gatsby-theme-c360-portal/src/components/ModelBrowser/SalesForecastFeatureBreakdown';
import * as React from 'react';
export default {
  ModelBrowser,
  QuickLook,
  IframeComponent,
  SalesForecastFeatureBreakdown,
  React
};