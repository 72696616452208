import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { event, select } from 'd3-selection';
import { zoom, zoomIdentity } from 'd3-zoom';
import dagreD3 from 'dagre-d3';
import { sliderRight } from 'd3-simple-slider';
import { pipelineJson } from '@tra-sg/gatsby-theme-c360-portal/src/data/config';
import { callApi } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';

function enableResetZoom() {
  document.getElementById('reset-zoom').disabled = false;
}

function disableResetZoom() {
  document.getElementById('reset-zoom').disabled = true;
}

function enableZoomIn() {
  document.getElementById('zoom-in').disabled = false;
}

function disableZoomIn() {
  document.getElementById('zoom-in').disabled = true;
}

function enableZoomOut() {
  document.getElementById('zoom-out').disabled = false;
}

function disableZoomOut() {
  document.getElementById('zoom-out').disabled = true;
}

function parseDate(dateString) {
  if (dateString) {
    let str_date = `${dateString} UTC`
    return (new Date(Date.parse(str_date))).toLocaleString();
  } else {
    return `Not available`
  }
}

export default class HealthcheckDiagram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoading: true,
      pipeline: null,
    };
  }

  fetchData() {
    const { date } = this.props;
    const { pipeline_name } = this.props;
    this.setState({ isLoading: true });

    callApi(
      `${pipelineJson}_${pipeline_name}_${date}.json`,
      (result) => {
        const loaded_pipeline = result.pipeline;
        if (loaded_pipeline == null) throw Error('Invalid pipeline info received.');
        this.setState({
          isLoading: false,
          error: null,
          pipeline: loaded_pipeline,
        });
        this.drawGraph();
      },
      (error) => this.setState({ error, isLoading: false })
    )
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date || this.props.pipeline_name !== prevProps.pipeline_name) {
      this.fetchData();
    }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  static selectNode(inner, g) {
    const fullSourceList = [];
    function getDependencies(table_id) {
      return g.predecessors(table_id);
    }
    function getFullSourceList(props) {
      if (props) {
        const dependencies = getDependencies(props);
        if (dependencies) {
          dependencies.forEach((table_id) => {
            if (table_id != "t150_customer_transaction") { //TODO: handle self recursive event data
              fullSourceList.push(table_id);
              getFullSourceList(table_id);
            }
          });
        }
      }
    }
    inner.selectAll('g.node')
      .on('mouseover', function onMouseOver(v) {
        fullSourceList.length = 0;
        // Reset the opacity of all nodes back to 1
        inner.selectAll('g.node').style('opacity', '0.5');
        // Change the opacity of selected node
        select(this).style('opacity', '1');
        // Select the element by class, use .text to set the content
        select('.infobox .name').text(g.node(v).label);
        select('.infobox .row_count').text(g.node(v).description);
        select('.infobox .status').text(g.node(v).class);
        select('.infobox .date').text(parseDate(g.node(v).date + " " + g.node(v).time));
        select('.infobox .time').text(g.node(v).time);
        getFullSourceList(g.node(v).label);
        if (fullSourceList) {
          fullSourceList.map((table_id) => {
            document.getElementById(table_id).setAttribute('style', 'opacity: 0.8');
            return fullSourceList;
          });
        }
        let selectedRow = document.getElementsByClassName('is-selected');
        for (let i = 0; i < selectedRow.length; i += 1) {
          selectedRow[i].classList.remove('is-selected');
        }
        selectedRow = document.getElementById(`${g.node(v).label}_tr`);
        if (selectedRow) {
          selectedRow.classList.add('is-selected');
        }
      });
  }

  drawGraph() {
    // Create the input graph
    const g = new dagreD3.graphlib.Graph()
      .setGraph({ rankdir: 'LR', ranker: 'longest-path' })
      .setDefaultEdgeLabel(() => ({}));

    this.state.pipeline.forEach(
      (pipeline_step) => {
        if (!pipeline_step.dataset_table_id.includes("_waiter") && !pipeline_step.dataset_table_id.includes("bq_")) {
          const name = pipeline_step.dataset_table_id;
          const { status, date } = pipeline_step;
          const time = pipeline_step.execution_time;
          const description = this.numberWithCommas(pipeline_step.row_count);
          g.setNode(name, {
            labelType: 'html',
            label: name,
            class: status,
            id: name,
            description,
            date,
            time,
          });

          pipeline_step.source.forEach(
            (source) => {
              const source_name = source.table_id;
              const source_date = source.date;

              if (!g.nodes().includes(source_name)) {
                g.setNode(source_name, {
                  labelType: 'html',
                  label: source_name,
                  title: source_name,
                  id: source_name,
                  class: status,
                  date: source_date,
                });
              }
              if(source_name != name) { //TODO: handle self recursive event data
                g.setEdge(source_name, name);
              }
            },
          );
        }
      },
    );

    g.nodes().forEach((v) => {
      const node = g.node(v);
      // Round the corners of the nodes
      node.rx = 5;
      node.ry = 5;
    });

    // Create the renderer
    // TODO: fix eslint error
    const render = new dagreD3.render(); // eslint-disable-line new-cap

    // Set up an SVG group so that we can translate the final graph.
    const svg = select(ReactDOM.findDOMNode(this.refs.nodeTree));

    // Run the renderer. This is what draws the final graph.
    const inner = select(ReactDOM.findDOMNode(this.refs.nodeTreeGroup));

    // Set up slider
    const slider = sliderRight()
      .default(0.7)
      .min(0.5)
      .max(1.3)
      .step(0.1)
      .width(100)
      // .height(900)
      .displayValue(true);

    select('#slider')
      .append('svg')
      .attr('width', 100)
      // .attr('height', 1000)
      .append('g')
      .attr('transform', 'translate(30,30)')
      .call(slider);

    // Set up zoom support
    const initialScale = 0.7;
    let currentZoom;
    const minZoom = 0.5;
    const maxZoom = 1.3;
    const d3zoom = zoom()
      .scaleExtent([minZoom, maxZoom])
      .on('zoom', () => {
        inner.attr('transform', event.transform);
        currentZoom = event.transform.k;
        slider.silentValue(currentZoom);
        if (event.transform.k !== initialScale) {
          enableResetZoom();
        } else {
          disableResetZoom();
        }
        if (event.transform.k >= maxZoom) {
          disableZoomIn();
        } else if (event.transform.k < maxZoom && event.transform.k > minZoom) {
          enableZoomIn();
          enableZoomOut();
        } else {
          disableZoomOut();
        }
      });
    slider.on('onchange', (val) => {
      d3zoom.transform(svg, zoomIdentity.translate((svg.attr('width') - g.graph().width * val) / 2, 20).scale(val));
    });
    svg.call(d3zoom);

    // Set up reset zoom
    document.getElementById('reset-zoom').addEventListener('click', () => {
      d3zoom.transform(svg, zoomIdentity.translate((svg.attr('width') - g.graph().width * initialScale) / 2, 20).scale(initialScale));
    });

    document.getElementById('zoom-in').addEventListener('click', () => {
      currentZoom = (currentZoom + 0.1 > maxZoom) ? maxZoom : (currentZoom + 0.1);
      d3zoom.transform(svg, zoomIdentity.translate((svg.attr('width') - g.graph().width * (currentZoom)) / 2, 20).scale(currentZoom));
    });

    document.getElementById('zoom-out').addEventListener('click', () => {
      currentZoom = (currentZoom - 0.1 < minZoom) ? minZoom : (currentZoom - 0.1);
      d3zoom.transform(svg, zoomIdentity.translate((svg.attr('width') - g.graph().width * currentZoom) / 2, 20).scale(currentZoom));
    });

    render(inner, g);

    HealthcheckDiagram.selectNode(inner, g);

    // Center the graph
    svg.attr('height', g.graph().height * initialScale + 40);
    svg.attr('display', '70 160 800 190');
    svg.attr('preserveAspectRatio', 'xMaxYMax meet');
    svg.attr('width', document.getElementById('pipeline-healthcheck').offsetWidth);
    svg.call(d3zoom.transform, zoomIdentity.translate((svg.attr('width') - g.graph().width * initialScale) / 2, 20).scale(initialScale));
  }

  static renderGraph() {
    return (
      <div>
        <div className="columns is-full is-desktop" id="pipeline-healthcheck">
          <div className="column">
            <div className="infobox">
              <h1>
Table:&nbsp;
                <strong className="name" />
              </h1>
              <p>
Rows:&nbsp;
                <strong className="row_count" />
              </p>
              <p>
Status:&nbsp;
                <strong className="status" />
              </p>
              <p>
Date:&nbsp;
                <strong className="date" />
              </p>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column is-full">
            <svg id="nodeTree" ref="nodeTree" height="600">
              <rect width="100%" height="100%" fill="#F8F8FF" />
              <g ref="nodeTreeGroup" />
              <foreignObject x="20" y="20" width="160" height="160">
                <div xmlns="http://www.w3.org/1999/xhtml">
                  <button className="button" id="zoom-out" display="none">
                    -
                  </button>
                  <button className="button is-primary" id="reset-zoom" display="none">
                    Reset
                  </button>
                  <button className="button" id="zoom-in" display="none">
                    +
                  </button>
                </div>
              </foreignObject>
            </svg>
          </div>
          <div id="slider" />
        </div>
      </div>
    );
  }

  renderError() {
    var message_class, error_reason, error_message;
    // if 404 error, then don't display
    // because it already displayed in
    // other part of same pipeline page
    if (this.state.error.message.includes('404')) {
      return ''
    } else {
      message_class = 'is-danger'
      error_reason = 'It seems like something went wrong with the Pipelines.'
      return (
        <div className="columns is-full">
          <article className={`message ${message_class}`}>
            <div className="message-body">
              { error_reason }
              <br />
              <i>
                {' '}
              Error:
                { this.state.error.message }
                {' '}

              </i>
            </div>
          </article>
        </div>
      );
    }
  }

  render() {
    const { error, isLoading } = this.state;

    if (error) {
      return (
        <div className="section">
          { this.renderError() }
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="columns is-centered">
          <div className="iframe-holder" />
        </div>
      );
    }

    return (
      <div className="section">
        { HealthcheckDiagram.renderGraph() }
      </div>
    );
  }
}
